@tailwind components;
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }

  * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }

  /* html {
    width: 100vw;
  } */

  body {
    margin: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    transition: all 0.2s;
  }

  #app {
    width: 100%;
  }

  /* a blue color as a generic focus style */
  button:focus-visible {
    outline: 2px solid #4a90e2 !important;
    outline: -webkit-focus-ring-color auto 5px !important;
  }

  a {
    text-decoration: none;
  }

  .box-rotate-45 {
    transform: rotate(45deg);
  }

  .blend-box-color {
    opacity: 43.2%;
    background-blend-mode: soft-light;
    background: linear-gradient(180deg, #63ceff 0%, rgba(7, 43, 255, 0) 100%);
  }

  .carousel-slider {
    width: 40px;
    height: 5px;
    border-radius: 5px;
    background: rgba(0, 186, 247, 1);
    cursor: pointer;
    transition: all 0.1s ease-in-out;
  }

  .slider-button {
    filter: invert(77%) sepia(57%) saturate(6810%) hue-rotate(158deg)
      brightness(103%) contrast(106%);
  }

  .slider-button:hover {
    filter: invert(61%) sepia(83%) saturate(2997%) hue-rotate(157deg)
      brightness(99%) contrast(100%);
    transform: scale(1.1);
    transition: all 0.3s;
  }

  .shadow-drop {
    filter: drop-shadow(0px 28px 29px rgba(0, 0, 0, 0.4));
  }

  ul > li:hover {
    color: #00baf7;
    cursor: pointer;
  }

  ul > li a:hover {
    color: #00baf7;
    cursor: pointer;
  }

  a:hover {
    color: #00baf7;
    cursor: pointer;
  }

  .btn {
    transition: all 0.1s ease-in-out;
    cursor: pointer;
  }

  .btn:hover {
    transform: scale(1.03);
  }

  .btn:active {
    transform: scale(1);
  }

  .blue-gradient {
    background: linear-gradient(180deg, rgb(0, 175, 255), rgb(7, 43, 255));
  }

  .toggle-container {
    background: linear-gradient(180deg, #00afff 0%, #00ffd0 100%);
    border-radius: 80px;
    padding: 0 5px;
    width: 50px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
  }

  .toggle-btn {
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 50%;
    transition: all 0.4s ease-in-out;
  }

  .active {
    transform: translateX(100%);
  }

  .recommended-card {
    background: linear-gradient(
      2.55deg,
      #115efb -29.62%,
      #2cb0fc 128.02%,
      #2cb0fc 129.62%
    );
    width: 406px;
    height: 660px;
    padding: 3px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
  }

  .custom-card {
    border-radius: 32px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 700;
    width: 300px;
    height: 420px;
    padding: 20px;
    border-radius: 32px 0px 0px 32px;
    background: white;
    margin: 0 5px;
    transition: all 0.2s ease-in-out;
  }

  .custom-card.primary {
    position: relative;
    z-index: 50;
    background: linear-gradient(
      2.55deg,
      #115efb -29.62%,
      #2cb0fc 128.02%,
      #2cb0fc 129.62%
    );
    width: 350px;
    height: 480px;
    padding: 3px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
  }

  .custom-card.secondary {
    background: linear-gradient(
      2.55deg,
      #115efb -29.62%,
      #2cb0fc 128.02%,
      #2cb0fc 129.62%
    );
    border-radius: 32px 32px 32px 32px;
  }

  .custom-card.outlined {
    background: white;
    border-radius: 32px 32px 32px 32px;
  }

  .custom-card:hover {
    transform: scale(1.05);
    cursor: pointer;
  }

  .custom-card:active {
    transform: scale(1);
    cursor: pointer;
  }

  .inner-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 12px 12px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .inner-card.primary {
    padding: 20px;
  }

  .badge {
    width: 159px;
    height: 40px;
    padding: 5px 5px;
    font-size: 1rem;
    border-radius: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .badge.primary {
    background: linear-gradient(
      2.55deg,
      #115efb -29.62%,
      #2cb0fc 128.02%,
      #2cb0fc 129.62%
    );
    color: white;
    font-family: Poppins;
  
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
  }

  .badge.outlined {
    background: rgba(234, 241, 254, 1);
    font-family: Poppins;

    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: rgba(17, 94, 251, 1);
  }

  .badge.secondary {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.02em;
    color: white !important;
    border: 1.6px solid white !important;
  }

  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .card-description {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0em;
  }

  .custom-btn {
    font-family: Poppins;
    font-size: 1rem;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    width: 100%;
    height: 50px;
    padding: 14px 10px;
    border-radius: 14px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
  }
  .custom-btn.primary {
    color: white;
    background: linear-gradient(
      2.56deg,
      #115efb -40.16%,
      #2cb0fc 138.36%,
      #2cb0fc 140.16%
    );
  }

  .custom-btn.secondary {
    color: rgba(17, 94, 251, 1);
    background: rgba(255, 255, 255, 1);
  }

  .custom-btn.outlined {
    background: none;
    color: rgba(17, 94, 251, 1);
    border: 2.4px solid rgba(17, 94, 251, 1);
  }

  .text-primary {
    color: rgba(17, 94, 251, 1);
    font-family: Poppins;
    font-size: 60px;
    font-weight: 700;
    line-height: 71px;
    letter-spacing: 0em;
  }

  .price-container {
    position: relative;
    font-size: 2.5rem;
  }

  .price-container > small {
    position: relative;
    top: -16px;
    font-family: Poppins;
    margin: 0 5px;
    font-size: .9rem;
    font-weight: 700;
    line-height: 60px;
    letter-spacing: 0em;
  }

  .price-container.secondary {
    color: white !important;
  }

  .period-description {
    font-family: Montserrat;
    font-size: .9rem;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }

  .storage-title {
    font-family: Montserrat;
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  .storage-title > p {
    font-family: Montserrat;
    font-size: .9rem;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
  }

  .download-cards-container {
    display: flex;
    width: 1, 114px;
    height: 700px;
    padding: 40px 0px 40px 0px;
    border-radius: 24px;
  }

  .card-head-container {
    font-family: Poppins;
    font-size: 35px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
  }

  .item-card-container {
    border-right: 1px solid rgba(50, 54, 73, 0.2);
  }

  .item-card-description {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
    letter-spacing: 0em;
  }

  .contact-card {
    width: 465px;
    height: 202px;
    border: 1px solid rgba(229, 234, 244, 1);
  }

  .contact-card-title {
    font-family: HK Grotesk;
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
  }

  .contact-card-message {
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
  }

  .questions-container {
    font-family: Montserrat;
    letter-spacing: 0em;
    text-align: center;
    width: 500px;
  }

  .questions-container > h2 {
    font-size: 35px;
    font-weight: 500;
    line-height: 43px;
    margin-bottom: 20px;
  }

  .questions-container > p {
    font-family: Helvetica;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: rgba(90, 113, 132, 1);
  }

  .collapse-title {
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 1.2rem;
    letter-spacing: 0em;
    transition: all 0.5s ease-in-out;
  }

  .collapse-container {
    border-radius: 15px;
    background: white;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;
  }

  .collapse-title.actived {
    color: rgba(21, 101, 216, 1);
  }

  .collapse-content {
    position: absolute;
    z-index: -1;
    width: 80%;
    opacity: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0em;
    /* transition: all .1s ease-in-out; */
  }

  .collapse-content.actived {
    margin-top: 15px;
    position: relative;
    opacity: 1;
    width: 95%;
    z-index: 1;
    transition: all 0.5s ease-in-out;
    color: rgba(90, 113, 132, 1);
  }

  .collapse-toggle {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    height: 25px;
    width: 25px;
    padding: 5px;
  }

  .collapse-toggle:hover {
    background: rgba(90, 113, 132, 0.4);
    border-radius: 50%;
  }

  .link-active {
    color: #00baf7;
    font-weight: bold;
  }

  .section-title {
    font-size: 2rem; /* 45px / 16px = 2.8125rem */
  }
  
  .section-subtitle {
    font-size: 1.8rem; /* 34px / 16px = 2.125rem */
  }
  
  .section-text {
    font-size: 1.1rem; /* 20px / 16px = 1.25rem */
  }
  
  .button-text {
    font-size: 1.1rem; /* 20px / 16px = 1.25rem */
  }
  
  .testimony-text {
    text-align: center;
    font-size: 1.2rem; /* 25px / 16px = 1.5625rem */
  }


  @media screen and (max-width: 300px) {
    .section-title {
      font-size: 1.3rem; /* Adjust font size for medium screens */
    }
  
    .section-subtitle {
      font-size: 1.1rem; /* Adjust font size for medium screens */
    }
  
    .section-text,
    .button-text {
      font-size: .8rem; /* Adjust font size for medium screens */
    }
  
    .testimony-text {
      font-size: .9750rem; /* Adjust font size for medium screens */
    }
  }

  /* Medium screens (md) */
@media (min-width: 300px) and (max-width: 652px) {
  .section-title {
    font-size: 1.8rem; /* Adjust font size for medium screens */
  }

  .section-subtitle {
    font-size: 1.5rem; /* Adjust font size for medium screens */
  }

  .section-text,
  .button-text {
    font-size: 1.1rem; /* Adjust font size for medium screens */
  }

  .testimony-text {
    font-size: 1.2rem; /* Adjust font size for medium screens */
  }
}

/* Medium screens (md) */
@media (min-width: 652px) and (max-width: 768px) {
  .section-title {
    font-size: 2.5rem; /* Adjust font size for medium screens */
  }

  .section-subtitle {
    font-size: 1.875rem; /* Adjust font size for medium screens */
  }

  .section-text,
  .button-text {
    font-size: 1.1rem; /* Adjust font size for medium screens */
  }

  .testimony-text {
    font-size: 1.40625rem; /* Adjust font size for medium screens */
  }
}


/* Large screens (lg) */
@media (min-width: 768px) and (max-width: 1024px) {
  .section-title {
    font-size: 2.5rem; /* 45px / 16px = 2.8125rem */
  }
  
  .section-subtitle {
    font-size: 2.1rem; /* 34px / 16px = 2.125rem */
  }
  
  .section-text {
    font-size: 1.5rem; /* 20px / 16px = 1.25rem */
  }
  
  .button-text {
    font-size: 1.5rem; /* 20px / 16px = 1.25rem */
  }
  
  .testimony-text {
    font-size: 1.7rem; /* 25px / 16px = 1.5625rem */
  }
}

/* Extra-large screens (xl) */
@media (min-width: 1024px) and (max-width: 1280px) {
  .section-title {
    font-size: 1.5rem; /* 45px / 16px = 2.8125rem */
  }
  
  .section-subtitle {
    font-size: 1.2rem; /* 34px / 16px = 2.125rem */
  }
  
  .section-text {
    font-size: 1.1rem; /* 20px / 16px = 1.25rem */
  }
  
  .button-text {
    font-size: 1.1rem; /* 20px / 16px = 1.25rem */
  }
  
  .testimony-text {
    font-size: 1.2rem; /* 25px / 16px = 1.5625rem */
  }
}



  .social-container {
    display: flex;
    gap: 31.5px;
    align-items: center;
    justify-content: center;
  }

  /* .social-icon:hover {
    fill: #00baf7;
  } */

  .social-container > svg:hover > path {
    fill: #00baf7;
  }

  .social-container > svg:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .social-container > svg:active {
    transform: scale(1);
  }

  .menu-san-btn {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 25px;
    height: 18px;
    cursor: pointer;
    transition: all 0.8s ease;
  }

  .menu-san-btn.activated {
    transform: rotate(135deg);
  }

  .mc-bar-1 {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 2.5px;
    top: 0;
    transition: top 0.2s ease;
    transition-delay: 450ms;
  }

  .mc-bar-2 {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 2.5px;
    top: 50%;
    transition: top 0.2s ease;
    transition-delay: 450ms;
  }

  .mc-bar-3 {
    position: absolute;
    background-color: black;
    width: 100%;
    height: 2.5px;
    top: 100%;
    transition: top 0.2s ease;
    transition-delay: 450ms;
  }

  .menu-san-btn.activated > .mc-bar-3 {
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
  }

  .menu-san-btn.activated > .mc-bar-1 {
    top: 50%;
    transition: all 0.2s ease-in-out;
  }

  .menu-san-btn.activated > .mc-bar-2 {
    opacity: 0;
  }

  .menu-san-btn.activated > .mc-bar-3 {
    top: 50%;
    transition: all 0.2s ease-in-out;
  }

  .mobile-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 200;
    top: 70px;
    right: 0;
    flex-direction: column;
    background: white;
    width: 100%;
    height: 350px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  .footer-curved {
    height: 200px;
    width: 100%;
    /* top:200px; */
    background-color: rgb(4, 20, 55);
    position: relative;
    overflow: hidden;
    top: 5px;
    z-index:-2;
  }

  .footer-curved:before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 170%;
    width: 110%;
    background-color: white;
    border-radius: 0 0 100% 100%;
    z-index: -1;
  }

  .table-title {
    font-family: Montserrat;
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
  }

  .table-header-title {
    font-family: Montserrat;
    font-size: 1.1rem;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }

  .table-row-description {
    font-family: Montserrat;
    font-size: .8rem;
    font-weight: 500;
    line-height: 1.01rem;
    letter-spacing: 0em;
    text-align: left;
  }

  .table-row-title {
    font-family: Montserrat;
    font-size: .9rem;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(51, 70, 104, 1);
  }

  .table-row-new {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-align: left;
    color: white;
    width: 51px;
    height: 22px;
    padding: 3px 8px 3px 8px;
    border-radius: 2px;
    background: #115EFB;
    margin: 0 0 0 10px;
  }

  @media (min-width:768px ) and (max-width: 1024px) {
    .custom-card{
      width: 40% !important;
    }
  }

  @media only screen and (max-width: 1025px) {
    .footer-curved:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 180%;
      background-color: white;
      border-radius: 0 0 100% 100%;
      z-index: -1;
    }

    .custom-card.primary {
      width: 90%;
      height: 500px;
    }

    .custom-card.outlined {
      width: 90%;
      height: 500px;
      border-radius: 20px;
      border-radius: 32px;
    }

    .inner-card.primary {
      padding: 20px;
    }

    .banner-image {
      left: 50%;
      transform: translate(-50%, 0);
      width: 150%;
      height: 320px;
    }

    .badge {
      width: 60%;
      height: 40px;
    }

    .badge.primary {
      font-size: 1rem;
    }

    .badge.outlined {
      background: rgba(234, 241, 254, 1);
      font-family: Poppins;
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: rgba(17, 94, 251, 1);
    }

    .badge.secondary {
      font-size: 20px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: white !important;
      border: 1.6px solid white !important;
    }

    .text-primary {
      font-size: 3rem;
    }

    .price-container > small {
      top: -16px;
      margin: 0 5px;
      font-size: 1.5rem;
    }

    .price-container.secondary {
      color: white !important;
    }

    .card-description {
      font-size: 1rem;
    }

    .download-cards-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: unset;
      width: 85%;
    }

    .item-card-container {
      border-right: none;
      border-bottom: 1px solid rgba(50, 54, 73, 0.2);
    }

    .contact-card {
      width: 100%;
      height: 202px;
      border: 1px solid rgba(229, 234, 244, 1);
      padding: 20px;
    }

    .questions-container {
      width: 90%;
    }

    .questions-container > h2 {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }

    .questions-container > p {
      font-size: 1rem;
    }

    .collapse-title {
      font-size: 1rem;
    }

    .collapse-content {
      font-size: 0.85rem;
      /* transition: all .1s ease-in-out; */
    }

    .table-title {
      font-size: 1.2rem;
    }
  
    .table-header-title {
      font-size: 1rem;
    }
  
    .table-row-description {
      font-size: 0.8rem;
    }
  
    .table-row-title {
      font-size: 0.8rem;
    }
  
    .table-row-new {
      font-size: 0.7rem;
    }
  }

  @media only screen and (min-width: 1025px) {
    .carousel-slider:hover {
      transform: scaleY(1.1);
      opacity: 0.7;
    }
  }
}
