:root {
  --animate-delay: 0.1s;
}

.animate-pulse-plnify {
  opacity: 0.2;
  transition: all 1s;
  animation: customblinking 10s;
  animation-delay: 3s;
}

.animate-pulse-plnify.keep {
  opacity: 1;
}

.testimony-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.desktop-image {
  filter: contrast(0%) brightness(90);
  margin-right: 10px;
}

@keyframes customblinking {
  0% {
    opacity: 1;
  }
  3% {
    opacity: 0.4;
  }
  4% {
    opacity: 1;
  }
  5% {
    opacity: 0.5;
  }
  6% {
    opacity: 1;
  }
  7% {
    opacity: 0.7;
  }
  8% {
    opacity: 1;
  }
  8.5% {
    opacity: 0;
  }
  9% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
