.arrow-animation {
  animation: attentiondown 3s ease-in-out infinite;
}

@keyframes attentiondown {
  0% {
    transform: translateY(25%);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(25%);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(25%);
  }
}
